import dynamic from 'next/dynamic';
import { ReactNode } from 'react';
import Top from '@components/Layout/Top';
import { PageWrapper } from '@components/Layout/PageWrapperMenu/styles';
import GlobalContainer from '@/components/Layout/GlobalContainer';
import { LayoutTypes } from '@/shared/types/home.types';
import HeadersPage from '@/components/Layout/Headers';
import useBackground from '@/hooks/useBackground';
import { useAmp } from 'next/amp';
import usePageViewComscoreRequest from '@/hooks/usePageViewComscoreRequest';
import usePageViewComscoreScript from '@/hooks/usePageViewComscoreScript';
import useAmpComponent from '@/hooks/useAmpComponent';
import Banner from './Banner';
import { ApplySchema } from '../Internals/InternalSchema';
import { CazambaAds } from '../Ads/CazambaAds';
import AdsGeneratorMemo from '../Ads/Google/AdsTypeGeneratorsMemo';
import Head from 'next/head';
import { useRouter } from 'next/router';
import getEnv from '@/utils/getEnv';

interface LayoutProps {
  children: ReactNode | ReactNode[];
  layoutData: LayoutTypes;
  showBackgroundAd?: boolean;
  headingTag?: 'h1' | 'h2';
  isAdvertorial?: boolean;
  dataLayer?: {
    category?: string;
    author?: string;
    editor?: string;
    contentType?: string;
  };
}

export default function Layout({
  children,
  layoutData,
  showBackgroundAd = true,
  headingTag = 'h2',
  isAdvertorial = false,
  dataLayer = undefined,
}: LayoutProps) {
  const AdsEvents = dynamic(() => import('@/components/Ads/Google/AdsEvents'), { ssr: false });
  const BackgroundGoogleAds = dynamic(() => import('@/components/Ads/Google/BackgroundGoogleAds'), { ssr: false });
  const OneAOneAdGeneratorMemo = dynamic(() => import('@/components/Ads/Google/OneAOne'), { ssr: false });
  const InterstitialsAds = dynamic(() => import('@/components/Ads/Google/Interstitial'), { ssr: false });
  const { Navbar } = useAmpComponent();
  const { isAdActive } = useBackground();

  const { segmentacoes, tagsData, navBarData, schema } = layoutData;

  const isAmp = useAmp();

  usePageViewComscoreRequest();
  usePageViewComscoreScript();

  return (
    <GlobalContainer>
      <Head>
        {dataLayer !== undefined && (
          <script
            id="data-layer"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];

              dataLayer.push({
                'category': '${dataLayer.category ? dataLayer.category : ''}',
                'authorData': '${dataLayer.author ? dataLayer.author : ''}',
                'editorData': '${dataLayer.editor ? dataLayer.editor : ''}',
                'contentType': '${dataLayer.contentType ? dataLayer.contentType : ''}',
              });
            `,
            }}
          />
        )}
        <script
          id="gtm-script"
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${getEnv('GOOGLE_TAG_MANAGER_ID')}');
            `,
          }}
        />
      </Head>
      {schema && <ApplySchema schema={schema} />}
      {!isAmp && <OneAOneAdGeneratorMemo />}
      {!isAmp && !isAdvertorial && <CazambaAds />}
      <AdsEvents segmentacoes={segmentacoes} />
      <HeadersPage headPage={tagsData} />
      <Navbar navBarContent={navBarData} headingTag={headingTag} />
      <Banner />
      {!isAmp && <Top Ads={showBackgroundAd ? isAdActive : false} shouldHaveAds={!isAdvertorial} />}
      {!isAdvertorial && <BackgroundGoogleAds />}
      <PageWrapper Ads={showBackgroundAd ? isAdActive : false} isAmp={isAmp}>
        {children}
        <InterstitialsAds />
      </PageWrapper>
      {!isAdvertorial && !isAmp && <AdsGeneratorMemo type="anchor" />}
      {!isAdvertorial && !isAmp && <AdsGeneratorMemo type="Anchor_Footer" />}
    </GlobalContainer>
  );
}
